import * as React from "react";
import { Button, Space, Select } from "antd";

interface SearchBoxProps {
  placeholder: string;
  onSearch?: (value: string[]) => void;
  onClear?: () => void;
  onLoader?: () => void;
  loader?: boolean;
  className?: string;
}

export const SearchBox: React.FC<SearchBoxProps> = ({
  placeholder,
  onSearch,
  onClear,
  onLoader,
  loader,
  className = "w-full",
}) => {
  const [defaultInputVal, setDefaultInputVal] = React.useState<string>("");
  const [inputVal, setInputVal] = React.useState<string[]>([]);
  const [onSearchPress, setOnSearchPress] = React.useState(false);

  const trimAndSplit = (inputString: string) => {
    if (!inputString) return [];

    return inputString
      .split(",")
      .map((item) => item.trim())
      .flatMap((item) => item.split(" "))
      .filter((item) => item !== "");
  };
  //   api.open({
  //     message: title ? title : "Solveesy",
  //     description: description ? description : "",
  //     duration: 3,
  //   });
  // };

  function unionTwoArrays(arr1: string[], arr2: string[]) {
    const map = new Map();
    [...arr1, ...arr2].forEach((item: string) => map.set(item, item));
    return Array.from(map.values());
  };

  const onEnterSearch = async () => {
    onLoader && onLoader();
    //console.log('#>>> onEnterSearch() == defaultInputVal :: ', defaultInputVal);
    const value = trimAndSplit(defaultInputVal);
    //console.log("#>>> onEnterSearch() ==> value :: ", [...unionTwoArrays([...inputVal], [...new Set(value)])]);
    setInputVal(prev => [...unionTwoArrays([...prev], [...new Set(value)])]);
    setOnSearchPress(true);
    onSearch && onSearch([...unionTwoArrays([...inputVal], [...new Set(value)])]);
  };

  const handleChange = (value: string[]) => {
    //console.log("#>>> handleChange() =>> value :: ", value.join(', '));
    setDefaultInputVal(value.join(', '));
    setInputVal(value);
  };

  const onInputChange = (value: string) => {
    //console.log("#>>> onInputChange() ==> value :: ", value);
    setOnSearchPress(false);
    setDefaultInputVal(value);
  };

  return (
    <div className="flex flex-row items-center justify-center">
      <div className="w-[550px]">
        <Space style={{ width: "100%" }} direction="vertical">
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder={placeholder}
            value={onSearchPress ? inputVal : undefined}
            onChange={handleChange}
            onSearch={onInputChange}
            showSearch={true}
            loading={loader}
            notFoundContent={null}
            suffixIcon={null}
            onClear={() => {
              if (onClear) {
                onClear();
                setInputVal([]);
                setDefaultInputVal("");
                setOnSearchPress(false);
                onSearch && onSearch([]);
              }
            }}
          />
        </Space>
      </div>
      <div className="ml-1">
        <Button
          onClick={() => {
            if (onSearch) onEnterSearch();
          }}
          disabled={loader}>
          Search
        </Button>
      </div>
    </div>
  );
};
