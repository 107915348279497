import { useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { DownloadOutlined, FilterOutlined } from "@ant-design/icons";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Col, Pagination, Row, Select, Table, notification, Tooltip, Space } from "antd";

import { getCasePayload, getCasePayloadType, getUserData, retCasesWord } from "../../../utils";
import { adminCasesTableColumn } from "./Columns";
import { useGlobalStore } from "../../../contexts/StoreContext";
import { downloadCases, getCommonAgents } from "../../../services/commonController";
import { assignToAgentMethod } from "../../../services/agentManagerController";
import { AdvanceFilter } from "../../AdvanceFilter";
import { SearchBox } from "../../SearchBox";

export function AgentManagerCasesTable({ data, totalRecords, isLoading, status_type }: any) {
  const TAG = "AgentManagerCasesTable: ";
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [emailOfSelectedAgent, setEmailOfSelectedAgent] = useState("");
  const [selectedAgent, setSelectedAgent] = useState(undefined);
  const [isAdvanceFilterOpen, setIsAdvanceFilterOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { state, dispatch } = useGlobalStore();
  const onSelectChange = (newSelectedRowKeys: any) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const userData = getUserData();
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const tableProps = state.activeTabKey !== "3" && {
    rowSelection: rowSelection,
  };

  const { data: commonAgentsResponse } = useQuery({
    queryKey: ["commonAgents"],
    queryFn: getCommonAgents,
  });

  const { mutate: handleAgentAssignment, isPending } = useMutation({
    mutationKey: ["assignToAgent"],
    mutationFn: assignToAgentMethod,
    onSuccess: (res: any, variables, context) => {
      if (res.status === 200) {
        notification.success({
          message: "Success",
          description: res.data.message,
        });
        queryClient.invalidateQueries({
          queryKey: ["agentManagerActiveCases"],
        });
        queryClient.invalidateQueries({
          queryKey: ["agentManagerPendingCases"],
        });
        queryClient.invalidateQueries({
          queryKey: ["agentManagerCompletedCases"],
        });

        setSelectedAgent(undefined);
        setSelectedRowKeys([]);
      }
    },
    onError: (error: any) => {
      notification.error({
        message: "Error",
        description: error.response.data.message,
      });
    },
  });

  const payload = getCasePayload(state);
  const casePayloadType: any = getCasePayloadType(state.activeTabKey);

  const toggleAdvanceFilter = () => setIsAdvanceFilterOpen(!isAdvanceFilterOpen);

  const handleOnChange = (page: number, pageSize: number) => {
    dispatch({
      type: casePayloadType,
      payload: {
        ...payload,
        page: page,
        size: pageSize,
      },
    });
  };

  const handleSorting = (sortBy: string, sortOrder: string) => {
    dispatch({
      type: casePayloadType,
      payload: {
        ...payload,
        sortBy: sortBy || "",
        sortOrder: sortOrder || "",
      },
    });
  };

  const getSource = () => {
    let source = "";
    if (state.activeTabKey === "1") {
      source = "pending";
    }
    if (state.activeTabKey === "2") {
      source = "active";
    }
    if (state.activeTabKey === "3") {
      source = "completed";
    }
    return source;
  };

  const source = getSource();

  const handleDownload = (source: string) => {
    downloadCases(source)
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${source}Cases.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading the cases:", error);
      });
  };

  const handleOnSearch = (ids: string[]) => {
    //console.log('#>>>> handleOnSearch() ++>> ids :: ', ids);
    dispatch({
      type: casePayloadType,
      payload: {
        ...payload,
        page: 1,
        loanIds: ids, // filter loanIds
      },
    });
  };

  return (
    <Col>
      <Row className="flex justify-between pb-4 gap-2">
        <Col className="flex pb-1">
          <SearchBox
            placeholder="Search..."
            onLoader={() => setLoader(true)}
            onSearch={(val: string[]) => {
              handleOnSearch(val);
              setLoader(false);
            }}
            loader={loader}
            onClear={() => handleOnChange(1, 10)}
          />
        </Col>
        <Col className="flex">
          {(userData?.roleName === "Agent-Manager" || userData?.roleName === "Legal-Manager") &&
            state.activeTabKey !== "3" && (
              <>
                <Space style={{ width: "200px", marginRight: "5px" }} direction="vertical">
                  <Select
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Select Agent"
                    onChange={(e) => setSelectedAgent(e)}
                    onSelect={(e, option) => setEmailOfSelectedAgent(option.email)}
                    value={selectedAgent}>
                    {commonAgentsResponse?.data?.map((item: any) => {
                      return (
                        <Select.Option
                          key={item.id}
                          value={item.id}
                          email={item.email}
                          disabled={!selectedRowKeys.length}>
                          {`${item.firstName} ${item.lastName}`}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Space>
                <Col>
                  <Button
                    loading={isPending}
                    type="primary"
                    onClick={() =>
                      handleAgentAssignment({
                        email: emailOfSelectedAgent,
                        procInstanceIds: selectedRowKeys,
                        reassignment: state?.activeTabKey === "2",
                      })
                    }
                    disabled={!selectedAgent}>
                    Confirm
                  </Button>
                </Col>
              </>
            )}
          <Col className="flex justify-center pl-2 pr-2 gap-3">
            <Tooltip title={`Filter ${retCasesWord(String(source))} data `}>
              {/* <Tooltip title="Case Filter"> */}
              <FilterOutlined className="cursor-pointer text-lg" onClick={toggleAdvanceFilter} />
            </Tooltip>
            <Tooltip title="Download data">
              <DownloadOutlined className="cursor-pointer text-lg" onClick={() => handleDownload(source)} />
            </Tooltip>
          </Col>
        </Col>
      </Row>
      {isAdvanceFilterOpen && (
        <Row className="pb-4">
          <AdvanceFilter toggleAdvanceFilter={toggleAdvanceFilter} />
        </Row>
      )}
      <Table
        {...tableProps}
        loading={isLoading || loader}
        columns={adminCasesTableColumn}
        dataSource={data || []}
        size="middle"
        pagination={false}
        onRow={(rowInfo) => ({
          onClick: () => {
            localStorage.setItem("caseType", rowInfo?.bucketName);
            const detailsPath = generatePath("/portfolio/:id", { id: rowInfo.key.toString() });
            navigate(detailsPath);
            dispatch({ type: "SET_BUCKET_NAME", payload: rowInfo?.bucketName });
          },
        })}
        onChange={(selectedRowKeys, selectedRows, info: any) => {
          handleSorting(info?.field, info?.order);
        }}
        style={{ cursor: "pointer" }}
      />
      <Row className="flex justify-center pt-6 pb-6">
        <Pagination
          showSizeChanger
          total={totalRecords}
          onChange={handleOnChange}
          current={payload.page}
          pageSize={payload.size}
        />
      </Row>
    </Col>
  );
}
