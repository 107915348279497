import { useRef, useState } from "react";
import { Layout, Menu, Typography } from "antd";
import { useNavigate } from "react-router-dom";

import {
  DashboardOutlined,
  CloudUploadOutlined,
  ToolOutlined,
  SettingOutlined,
} from "@ant-design/icons";

import { getUserData } from "../../utils";
import { useGlobalStore } from "../../contexts/StoreContext";

import { smallLogo, bigLogo } from "../../utils/image";


const { Sider } = Layout;
const { Title } = Typography;

const bgStyle = { backgroundColor: "rgb(111, 86, 163)", minHeight: "100vh" };

type SidebarTypes = { collapsed: false | true; };

const Sidebar: any = (props: SidebarTypes) => {

  const { collapsed } = props;
  const { state, dispatch } = useGlobalStore();
  const navigate = useNavigate();
  const userData = getUserData();
  const userRole = userData?.roleName;

  const items = [
    {
      key: "1",
      icon: <DashboardOutlined />,
      label: "Portfolio",
    },

    ...(userRole === "Admin" || userRole === "Agent-Manager"
      ? [{ key: "2", icon: <CloudUploadOutlined />, label: "Data Upload" }]
      : []),

    ...(userRole === "Admin"
      ? [
        {
          key: "3",
          icon: <ToolOutlined />,
          label: "User Management",
        },
      ]
      : []),

    {
      key: "4",
      icon: <SettingOutlined />,
      label: "Settings",
    },
  ];

  const handleClick = ({ key, keyPath }: any) => {
    dispatch({ type: "SET_SIDEBAR_ACTIVE_KEY", payload: key });
    const actionMap: { [key: string]: () => void } = {
      "1": () => {navigate("/"); window.location.reload();},
      "2": () => navigate("/data-upload"),
      "3": () => navigate("/user-management"),
      "4": () => navigate("/settings"),
    };
    const action = actionMap[key];
    if (action) {
      action();
    }
  };

  return (
    <Sider trigger={null} collapsible collapsed={collapsed} style={bgStyle}>
      {collapsed ? (
        <div className="" style={{ padding: "1.5rem" }}>
          <img src={smallLogo} alt="loading..." />
        </div>
      ) : (
        <div className="" style={{ padding: "2rem" }}>
          <img src={bigLogo} alt="loading..." />
        </div>
      )}

      <Menu
        defaultSelectedKeys={[state.activeSidebarKey]}
        activeKey={state.activeSidebarKey}
        className="text-left"
        theme="light"
        mode="inline"
        items={items}
        style={bgStyle}
        onClick={(e: any) => handleClick(e)}
      />
    </Sider>
  );
};

export default Sidebar;
