import { Avatar, TableColumnsType, Tooltip, Typography } from "antd";
import { decryptData, getInitials } from "../../../utils";
export type AdminCasesTableColumnsTypes = {
  loanId: React.ReactNode;
  key: React.Key;
  borrowerName: React.ReactNode;
  nbfc: React.ReactNode;
  dpdStatus: React.ReactNode;
  loanAmount: React.ReactNode;
  dueAmount: React.ReactNode;
  status: React.ReactNode;
  lastCallStatus: React.ReactNode;
  bucketName: string;
};

export const adminCasesTableColumn: TableColumnsType<AdminCasesTableColumnsTypes> =
  [
    {
      title: "Loan ID",
      dataIndex: "loanId",
      render: (id) => <span>{id || "-"}</span>,
    },
    {
      title: "Borrower Name",
      dataIndex: "borrowerName",
      sorter: (a: any, b: any) => a.borrowerName,
      render: (name) => {
        const decryptedName = decryptData(name);
        return (
          <Tooltip title={decryptedName || "-"}>
            <span className="flex flex-wrap" style={{ maxWidth: "150px" }}>
              {decryptedName || "-"}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: "Lender",
      dataIndex: "clientName",
      render: (clientName) => <span>{clientName || "-"}</span>,
    },
    {
      title: "DPD Status",
      dataIndex: "dpdStatus",
      sorter: (a: any, b: any) => 0,
      render: (dpdStatus) => {
        let bg = "";
        let status = "";
        if (dpdStatus === "DPD 0-30") {
          bg = "#6aa84f";
          status = "0-30";
        } else if (dpdStatus === "DPD 60-90") {
          bg = "#f1c232";
          status = "60-90";
        } else if (dpdStatus === "DPD 30-60") {
          bg = "#3d85c6";
          status = "30-60";
        } else {
          bg = "#cc0000";
          status = "90+";
        }

        return (
          <Tooltip title={dpdStatus || "-"}>
            <Typography.Text
              style={{
                background: bg,
                padding: status === "0-30" ? ".25rem 0.5rem" : " .25rem 0.5rem",
                borderRadius: "10px",
                maxWidth: "1rem",
                color: "white",
                fontWeight: "bold",
                fontSize: "15px"
              }}
            >
              {status || <span style={{ color: "gray" }}> -</span>}
            </Typography.Text>
          </Tooltip>
        );
      },
    },
    {
      title: "Loan Amount",
      dataIndex: "loanAmount",
      sorter: (a: any, b: any) => 0,
      render: (loanAmount) => (
        <span>{loanAmount ? `₹  ${loanAmount}` : "-"}</span>
      ),
    },
    {
      title: "Due Amount",
      dataIndex: "dueAmount",
      sorter: (a: any, b: any) => 0,
      render: (dueAmount) => <span>{dueAmount ? `₹  ${dueAmount}` : "-"}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <Tooltip title={status || "-"}>{status || "-"}</Tooltip>
      ),
    },
    {
      title: "Agent",
      dataIndex: "assignee",
      render: (agentName) => {
        const initials = getInitials(agentName);
        return agentName === "NA" || agentName === "" ? (
          <span>-</span>
        ) : (
          <Tooltip title={agentName || "Not Available"}>
            <Avatar style={{ backgroundColor: "#fde3cf", color: "#f56a00" }}>
              {initials}
            </Avatar>
          </Tooltip>
        );
      },
    },
    {
      title: "Last Call Date",
      dataIndex: "lastCallDate",
      render: (lastCallDate) => <span>{lastCallDate || "N/A"}</span>,
    },
    {
      title: "Next Call Date",
      dataIndex: "nextScheduledCallDate",
      render: (nextScheduledCallDate) => <span>{nextScheduledCallDate || "N/A"}</span>,
    }
  ];
